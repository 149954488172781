/** @format */
import React, { useContext, useMemo } from 'react';
import { ProjectsContext } from './ProjectsContext';
import classNames from 'classnames';
import { Archive, MoveIcon, XIcon } from 'lucide-react';
import { ProjectContentsToolbarButton as Icon } from './ProjectContentsToolbarButton';
// import { useConfirmDialog } from '../dialog/hooks';
import { pluralize } from 'javascripts/helpers/pluralize';
import { useConfirmDialog } from '../dialog/hooks';

export const ProjectContentsToolbar: React.FC = () => {
  const {
    setIsBulkMoveModalOpen,
    selectedProjects,
    selectedStoryboards,
    setSelectedProjects,
    setSelectedStoryboards,
    archiveItems,
    activeGroup,
    isSingleStoryboardMove,
  } = useContext(ProjectsContext);

  const totalSelected = useMemo(() => {
    return selectedProjects.length + selectedStoryboards.length;
  }, [selectedProjects, selectedStoryboards]);

  const dialogSubtitle = useMemo(() => {
    if (selectedProjects.length && selectedStoryboards.length) {
      return `You are about to archive ${pluralize(
        selectedProjects.length,
        'project',
      )} and ${pluralize(selectedStoryboards.length, 'storyboard')}.`;
    } else if (selectedStoryboards.length) {
      return `You are about to archive ${pluralize(
        selectedStoryboards.length,
        'storyboard',
      )}.`;
    } else if (selectedProjects.length) {
      return `You are about to archive ${pluralize(
        selectedProjects.length,
        'project',
      )}.`;
    } else {
      return '';
    }
  }, [selectedProjects, selectedStoryboards]);

  const canMoveProject = activeGroup?.actions.includes('projects.move');
  const canDeleteProject = activeGroup?.actions.includes('projects.delete');

  const { showDialog } = useConfirmDialog({
    size: 'confirm',
    isDark: true,
    alignButtons: 'left',
    cancelBtnSize: 'sm',
    confirmBtnSize: 'sm',
    title: 'Delete items?',
    subtitle: `${dialogSubtitle} Do you want to proceed?`,
    hideDescription: true,
    onConfirm: () => archiveItems({ useBulkSelection: true }),
    initialFocus: 'confirmButton',
  });

  const visible = totalSelected > 0 && !isSingleStoryboardMove;

  if (!canMoveProject && !canDeleteProject) {
    return null;
  }

  return (
    <div
      className={classNames(
        'fixed bottom-4 left-1/2 transform -translate-x-1/2 z-50 transition-all duration-300 ease-in-out w-full max-w-lg',
        visible ? 'translate-y-0' : 'translate-y-72 pointer-events-none',
      )}
    >
      <div
        className={classNames(
          'flex py-4 px-4 bg-black text-white rounded-lg shadow-md transition duration-300 ',
        )}
      >
        <div className="flex items-center flex-auto space-x-2">
          <Icon
            Icon={XIcon}
            onClick={() => {
              setSelectedProjects([]);
              setSelectedStoryboards([]);
            }}
          />
          <div className="flex-auto text-sm -mt-0.5">
            {`${totalSelected} item${totalSelected !== 1 ? 's' : ''} selected`}
          </div>
          <div className="flex items-center flex-shrink-0 space-x-2">
            {canMoveProject && (
              <Icon
                Icon={MoveIcon}
                label={`Move`}
                onClick={() => {
                  setIsBulkMoveModalOpen(true);
                }}
              />
            )}
            {canDeleteProject && (
              <Icon Icon={Archive} label={`Archive`} onClick={showDialog} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
