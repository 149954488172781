/** @format */

import React from 'react';
import { Settings, Archive, UserPlus, type LucideIcon } from 'lucide-react';
import { ProjectsContext, type ProjectGroup } from '../ProjectsContext';
import { Listbox } from '@headlessui/react';
import classNames from 'classnames';
import Icon from '../../icon/Icon';
import ArrowDownIcon from '../../../images/icons/arrow.svg';
import Panel from '../../panel/Panel';
import { ProjectsGroupLabel } from '../ProjectsGroupLabel';
import { TeamPlanBadge } from 'blackbird/components/team/TeamPlanBadge';
import { TeamTrialBlock } from 'blackbird/components/team/TeamTrialBlock';
import { ProjectDropZone } from '../modals/ProjectDropZone';
import { DRAGGABLE_TYPES } from 'javascripts/components/shared/dragAndDropUtils';

const accept = [DRAGGABLE_TYPES.project];
interface ProjectsTeamDropdownProps {
  group: ProjectGroup;
}

export const ProjectsTeamDropdown: React.FC<ProjectsTeamDropdownProps> = ({
  group,
}) => {
  const { setViewType } = React.useContext(ProjectsContext);

  const DropdownItem: React.FC<{ Icon: LucideIcon; text: string }> = ({
    Icon,
    text,
  }) => (
    <div className="flex items-center space-x-3">
      <Icon className="flex-shrink-0 w-4 h-4" />
      <div className="text-sm pb-0.5">{text}</div>
    </div>
  );

  const regularOptions = [
    {
      label: <DropdownItem Icon={UserPlus} text={`Invite members`} />,
      value: '/settings/team/manage',
    },
    {
      label: <DropdownItem Icon={Settings} text={`Team settings`} />,
      value: '/settings/team/general',
    },
  ];

  const archiveOption = {
    label: <DropdownItem Icon={Archive} text={`Archive`} />,
    value: 'archive',
  };

  const handleChange = (option: { label: React.ReactNode; value: string }) => {
    if (option.value === 'archive') {
      setViewType('archive');
    } else {
      window.location.href = option.value;
    }
  };

  if (!group.actions.includes('team.manage')) {
    return <ProjectsGroupLabel group={group} />;
  }

  return (
    <ProjectDropZone className="p-2 -m-2" accept={accept} projectId={null}>
      <Listbox
        as="div"
        className="relative group/teamdropdown"
        onChange={handleChange}
        value={null}
      >
        {({ open }) => (
          <>
            <Listbox.Button className="flex items-center text-base rounded-sm outline-none ring-none">
              <ProjectsGroupLabel group={group} />
              <Icon
                icon={<ArrowDownIcon />}
                className={classNames(
                  'flex-shrink-0 h-6 transition-all ml-0 mt-0.5 opacity-40 group-hover/teamdropdown:opacity-100',
                  open && '-rotate-180',
                )}
                color="black"
              />
            </Listbox.Button>

            <Listbox.Options
              as={Panel}
              className="absolute flex-col w-56 mt-2 left-8 top-full focus:outline-none"
            >
              <div className="p-2">
                <div className="px-2 pt-1 pb-3 mb-2 border-b border-border">
                  {group.plan_name === 'Trialing' ? (
                    <TeamTrialBlock />
                  ) : (
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <TeamPlanBadge planName={group.plan_name} />
                      </div>
                      <div className="flex-auto ml-1.5 text-type-subdued text-xs pt-0.5">
                        {group.quantity === 1 ? (
                          <>{`1 member`}</>
                        ) : (
                          <>{`${group.quantity} members`}</>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {regularOptions.map((option) => (
                  <Listbox.Option
                    key={option.value}
                    value={option}
                    as="div"
                    className={({ active }) =>
                      classNames(
                        'cursor-pointer select-none relative py-2 pl-3 pr-9 rounded',
                        active && 'bg-surface-light',
                      )
                    }
                  >
                    {({ selected }) => (
                      <>
                        <span className={classNames('block')}>
                          {option.label}
                        </span>
                      </>
                    )}
                  </Listbox.Option>
                ))}
                {group.actions.includes('storyboards.create') && (
                  <>
                    <div className="my-2 border-t border-border"></div>
                    <Listbox.Option
                      key={archiveOption.value}
                      value={archiveOption}
                      as="div"
                      className={({ active }) =>
                        classNames(
                          'cursor-pointer select-none relative py-2 pl-3 pr-9 rounded',
                          active ? 'bg-surface-light' : '',
                        )
                      }
                    >
                      {({ selected }) => (
                        <>
                          <span className={classNames('block')}>
                            {archiveOption.label}
                          </span>
                        </>
                      )}
                    </Listbox.Option>
                  </>
                )}
              </div>
            </Listbox.Options>
          </>
        )}
      </Listbox>
    </ProjectDropZone>
  );
};
